import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "small-content setup-page" }
const _hoisted_2 = { class: "medium-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setup_module_header = _resolveComponent("setup-module-header")!
  const _component_setup_title_input = _resolveComponent("setup-title-input")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_setup_done_button = _resolveComponent("setup-done-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_setup_module_header, {
        title: _ctx.lang.t('title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_setup_title_input, {
                label: _ctx.lang.t('titleLabel'),
                module: "discuss"
              }, null, 8, ["label"]),
              _createVNode(_component_ion_card, { class: "h-fit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.lang.t("showTime")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_toggle, {
                                modelValue: _ctx.CreateService.info.config.discuss.showTime,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.CreateService.info.config.discuss.showTime) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_setup_done_button, {
                        disabled: !_ctx.validator.valid,
                        error: _ctx.lang.t(_ctx.validator.message)
                      }, null, 8, ["disabled", "error"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}