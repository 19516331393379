
import { computed, defineComponent, onBeforeMount } from "vue";

import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCard,
  IonCardContent,
} from "@ionic/vue";

import SetupModuleHeader from "./SetupModuleHeader.vue";
import SetupDoneButton from "./SetupDoneButton.vue";
import SetupTitleInput from "./SetupTitleInput.vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";
import { ValidationResult } from "../../../lib/form/Validation";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "SetupDiscuss",
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonToggle,
    IonCard,
    IonCardContent,
    SetupModuleHeader,
    SetupDoneButton,
    SetupTitleInput,
  },
  setup() {
    const lang = LanguageService.getTranslator("module/discuss");
    const router = useRouter();
    const validator = computed<ValidationResult>(() => {
      CreateService.configState.discuss = false;
      if (CreateService.info.config.discuss) {
        if (CreateService.info.config.discuss.title.length <= 2) {
          return {
            valid: false,
            message: "titleTooShort",
          };
        }
        CreateService.configState.discuss = true;
        return { valid: true };
      } else {
        return { valid: false };
      }
    });

    onBeforeMount(() => {
      if (CreateService.info.config.discuss === undefined) {
        CreateService.info.config.discuss = {
          title: lang.t("title"),
          showTime: true,
        };
        CreateService.configState.discuss = false;
      }
      if (!CreateService.checkGuard("setup")) {
        router.push("/create");
      }
    });

    return {
      lang,
      CreateService,
      validator,
    };
  }
});
